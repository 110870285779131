<script>
    import LabelledFormInput from "./LabelledFormInput.svelte";
    import StyledInputField from "./StyledInputField.svelte";

    export let visuallyHidden;
    export let label='Passord';
    export let autocomplete = 'current-password';
    export let name = 'password';

    let type = 'password';

    let showHidePasswordButtonText = 'Vis';

    function toggleShowHidePassword() {
        if (type === 'text') {
            type = 'password';
            showHidePasswordButtonText = 'Vis';
        } else {
            type = 'text';
            showHidePasswordButtonText = 'Skjul';
        }
    }
</script>

<LabelledFormInput {name} {label} {visuallyHidden}>
    <StyledInputField {name} {type} {autocomplete} spellcheck={false} autoCapitalize={false} {visuallyHidden} on:change />
    <button type="button" on:click={toggleShowHidePassword} on:keypress={toggleShowHidePassword}>{showHidePasswordButtonText}</button>
</LabelledFormInput>

<style>
    button {
        box-sizing: content-box;
        background: var(--gray);
        text-align:center;
        height: 30px;
        line-height:30px;
        width: 50px;
        text-decoration:none;
        color: var(--black);
        font-size:12px;
        font-weight:bold;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        cursor: default;
        border: none;
        padding: 0;
    }

    :global(input[type="password"]::-ms-clear),
    :global(input[type="password"]::-ms-reveal) {
        display: none;
    }
</style>